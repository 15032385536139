@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.slateblue {
  background-color: slateblue;
}

.rotated {
  transform: rotate(10deg);
  color: #c6c3f3;
  font-weight: bolder;
}

.flexwrapper {
  font-family: "Poppins", sans-serif;
  background-color: rgb(66, 203, 196);
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  .flex {
    display: flex;
    justify-content: center;
    .txt {
      flex-grow: 1;
    }
    .input {
      flex-grow: 11;
      width: 70%;
    }
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

    .zero {
      margin-top: 7rem;
      font-family: "Poppins", sans-serif;
      color: whitesmoke;
      display: flex;
      justify-content: space-between;
      .one {
        flex-grow: 1;
      }
      .input {
        flex-grow: 5;
        color: white;
      }
      .form-control {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.9);
        background: transparent;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.9);
      }
      .form-control:focus {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.9);
        background: transparent;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.9);
      }
    }

.one {
  margin-top: 3rem;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  display: flex;
  justify-content: space-between;
  .one {
    flex-grow: 1;
  }
  .input {
    flex-grow: 5;
    color: white;
  }
  .form-control {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.9);
  }
  .form-control:focus {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.9);
  }
}

.two,
.three,
.four {
  margin-top: 3rem;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .one {
    flex-grow: 1;
  }
  .heading {
    flex-grow: 10;
    text-align: start;
  }
}

.jumbotron {
  color: white;
  .form-control {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.9);
    font-weight: bold;
  }
  .form-control:focus {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.9);
  }
}
.form-control::-moz-placeholder {
  font-weight: bold;
}
