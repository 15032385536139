@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  font-family: "Poppins", sans-serif;
  background: rgb(142, 197, 252);  
  background: linear-gradient(
    90deg,
    rgb(90, 159, 228) 0%,
    rgb(197, 152, 240) 85%
  );
}
